import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const StripeDonation = () => {
  const [loading, setLoading] = useState(false);

  const handleDonation = async (priceId) => {
    setLoading(true);
    const functions = getFunctions();
    const createStripeCheckout = httpsCallable(functions, 'createStripeCheckout');
    
    try {
      const { data } = await createStripeCheckout({
        price: priceId,
        success_url: `${window.location.origin}/#success`,
        cancel_url: `${window.location.origin}/#cancel`,
      });
      
      if (data && data.url) {
        window.location.assign(data.url);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.title}>hey friend! what do u wnt?</h3>
      <div style={styles.buttonContainer}>
        <button 
          style={styles.button} 
          onClick={() => handleDonation('price_1Pd9mDRtxMlTuS1DAKfdM6EG')} 
          disabled={loading}
        >
          1 Coffee ($1)
        </button>
        <button 
          style={styles.button} 
          onClick={() => handleDonation('price_1Pd9mWRtxMlTuS1DSwOu0Xtm')} 
          disabled={loading}
        >
          3 Coffees ($3)
        </button>
        <button 
          style={styles.button} 
          onClick={() => handleDonation('price_1Pd9nDRtxMlTuS1DCaJSBytS')} 
          disabled={loading}
        >
          5 Coffees ($5)
        </button>
        <button 
          style={styles.button} 
          onClick={() => handleDonation('price_1PrkeaRtxMlTuS1Ds01C2THq')} 
          disabled={loading}
        >
          10 Coffees ($10)
        </button>
        <button 
          style={styles.button} 
          onClick={() => handleDonation('price_1PdAbRRtxMlTuS1DpgVkuGom')} 
          disabled={loading}
        >
          a BIG pot of coffee ($25)
        </button>
      </div>
      {loading && <p style={styles.loading}>Processing your donation...</p>}
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#1a1a1a',
    border: '1px solid #1c1',
    padding: '20px',
    marginTop: '20px',
  },
  title: {
    color: '#1c1',
    fontSize: '24px',
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  button: {
    backgroundColor: '#1c1',
    color: '#0f0f0f',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    fontFamily: 'monospace',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  loading: {
    color: '#1c1',
    marginTop: '10px',
  },
};

export default StripeDonation;
