import React from 'react';

const Projects = () => {
  return (
    <div style={styles.projectsContainer}>
      <h2 style={styles.sectionTitle}>Projects</h2>
      
      {/* YouTube Video Embed */}
      <div style={styles.videoContainer}>
        <iframe 
          style={styles.videoIframe}
          src="https://www.youtube.com/embed/7rYR_l9oqQg?si=W42_zZnv3p9V7rbr" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowFullScreen
        ></iframe>
      </div>

      <div style={styles.projectsList}>
        <div style={styles.projectItem}>
          <a href="https://real-time-website-ef2f8.web.app/" style={styles.projectLink} target="_blank" rel="noopener noreferrer">
            Real Time Website Builder (Click Here)
          </a>
          <p style={styles.projectDescription}>
            A dynamic website builder that allows real-time editing and preview.
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  projectsContainer: {
    backgroundColor: '#1a1a1a',
    border: '1px solid #1c1',
    padding: '20px',
    marginTop: '20px',
    width: '100%',
    boxSizing: 'border-box',
  },
  sectionTitle: {
    fontSize: '24px',
    color: '#fff',
    marginBottom: '20px',
    textAlign: 'center',
  },
  videoContainer: {
    width: '100%',
    height: '400px', // Fixed height for desktop
    marginBottom: '20px',
    '@media (max-width: 768px)': {
      height: 'auto', // Responsive height for mobile
    }
  },
  videoIframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
  projectsList: {
    width: '100%',
  },
  projectItem: {
    marginBottom: '20px',
    textAlign: 'left',
  },
  projectLink: {
    color: '#1c1',
    textDecoration: 'none',
    fontSize: '18px',
    fontWeight: 'bold',
    display: 'inline-block',
    marginBottom: '5px',
  },
  projectDescription: {
    color: '#ddd',
    marginTop: '5px',
    fontSize: '14px',
  },
};

export default Projects;